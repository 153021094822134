<template>
  <div class="patient-and-doctor-container flex">
    <div class="left-options">
      <div v-if="attendance && attendance.patient" class=" patient-container">
        <b-skeleton v-if="loading" size="48px" type="avatar"></b-skeleton>
        <Avatar
          v-else
          size="48px"
          :src="`${attendance.patient.picture}?x=${moment()}`"
        />
        <b-col>
          <div>
            <b-skeleton v-if="loading"></b-skeleton>
            <div v-else class="patient-name">
              {{ attendance.patient.name }}
              <img v-if="isBirthday" class="confetti" src="@/assets/gifs/confetti.gif" v-b-tooltip.hover :title="`Hoje ${attendance.patient.name?.split(' ')[0]} faz aniversário`" />
            </div>
          </div>
            <div class="patient-row">
              <div class="patient-border"  v-if="attendance.patient.birthday">
              <span class="patient-age">
                <p class="value" v-if="moment().diff(moment(attendance.patient.birthday),'days') <= 30 " >
                  {{ moment().diff(moment(attendance.patient.birthday),'days') }} dias
                </p>
                <p class="value" v-else-if="moment().diff(moment(attendance.patient.birthday),'month') > 1 && moment().diff(moment(attendance.patient.birthday),'month') < 12" >
                  {{ moment().diff(moment(attendance.patient.birthday),'month') }} meses
                </p>
                <p class="value" v-else-if="!attendance.patient.birthday" >
                  -
                </p>
                <p class="value" v-else >
                  {{ moment().diff(moment(attendance.patient.birthday),'years') }} anos
                </p>
              </span>
              </div>
              <div class="divisor" v-if="attendance.patient.birthday">|</div>
              <b-skeleton v-if="loading"></b-skeleton>
              <div v-else class="medical-record">
                Nº do prontuário:
                <span>{{ attendance.patient.medical_record_number }}</span>
              </div>
              <div class="divisor" v-if="hasAppointment">|</div>
              <div :class="['icon', getClassPriority(attendance.appointment?.priority_level)]" v-if="hasAppointment">
              <component :is="getIconPriority(attendance.appointment?.priority_level)" v-if="hasAppointment"/>
              <div v-if="hasAppointment">{{ getLabelPriority(attendance.appointment?.priority_level) }}</div>
        </div>
            </div>

        </b-col>
      </div>
      <!-- <div class="padding-border">
        <Icon :tooltip="true ? 'Abrir triagem' : 'Triagem indisponível'">
          <div class="icon-wrapper" :class="{ active: true }">
            <Sorting class="icon" />
          </div>
        </Icon>
      </div> -->
      <!-- <div class="padding-border">
        <Icon tooltip="Visualizar e comparar exames">
          <div class="icon-wrapper">
            <Exam class="icon" />
          </div>
        </Icon>
      </div> -->
    </div>
    <div class="center-options">
      <div class="padding-border" v-if="attendance?.type === 'ESSILOR_ATTENDANCE'">
        <Icon>
          <div class="icon-wrapper" id="tooltip-essilor" @click="importEssilorExams">
            <EyeExams class="icon essilor-exams" />
          </div>
          <b-tooltip target="tooltip-essilor" trigger="hover" placement="bottom">
            Importar resultado de exames
          </b-tooltip>
        </Icon>
      </div>
      <div class="padding-border">
        <Icon tooltip="Histórico">
          <div class="icon-wrapper" >
            <ShowHistory type="sidebar"/>
          </div>
        </Icon>
      </div>
      <div class="padding-border">
        <Icon>
          <div class="icon-wrapper" id="tooltip-patient">
            <InfoPaciente class="icon" />
          </div>
          <TooltipInfoPatient target="tooltip-patient" :patient="attendance?.patient"/>
        </Icon>
      </div>
      <div v-can="'FpPac1'" class="padding-border ">
        <Icon tooltip="Visualizar cadastro do paciente">
          <div class="icon-wrapper" @click="goToProfile">
            <Profile class="icon" />
          </div>
        </Icon>
      </div>
      <div class="padding-border" v-if="attendance?.type === 'ATTENDANCE' || attendance?.type === 'SURGERY' || attendance?.type === 'BV_PRESCRIPTION'" >
        <Icon :tooltip="hasPreConsultation ? 'Abrir pré-consulta' : 'Pré-consulta indisponível'">
          <div class="icon-wrapper" :class="{ active: hasPreConsultation, inactive: !hasPreConsultation }" @click="openPreConsultation">
            <PreConsultation class="icon" />
          </div>
        </Icon>
      </div>
      <div class="padding-border" v-if="attendance?.type === 'ATTENDANCE' || attendance?.type === 'EXAM'  || attendance?.type === 'BV_PRESCRIPTION' || attendance?.type === 'BV_EXAM' || attendance?.type === 'ESSILOR_ATTENDANCE'">
        <Icon :tooltip="`${canEdit ? 'Impressão rápida' : 'Não é possível usar este recurso em consultas finalizadas'}`">
          <div class="icon-wrapper" :class="{ active: canEdit, inactive: !canEdit }" @click="canEdit ? $bvModal.show('print-medical-records-modal') : null" >
            <Printer class="icon stroke" />
          </div>
        </Icon>
      </div>
      <div class="padding-border" v-if="attendance?.patient.is_bv">
        <Icon tooltip="Ver triagem BV">
          <div class="icon-wrapper bv" @click="$bvModal.show('bv-clinic-report-modal')">
            <Export class="icon stroke" />
          </div>
        </Icon>
      </div>
    </div>

    <div class="right-options">
      <b-button
        v-if="canEdit"
        :disabled="loading"
        @blur="resetDropdownState"
        class="finish-dropdown__button"
        @click="handleOpenFinishOptions"
      >
        <div class="dropdown-call__button">
          <span>Finalizar</span>
          <span class="chevron__container ml-2">
            <ChevronDown class="chevron" :class="{'rotate': openFinishOptions}" />
          </span>
        </div>

        <ul class="dropdown__container" v-if="openFinishOptions">
          <li>
            <b-button class="dropdow__item" @click="onFinishAttendance">
              {{ (certificate && certificate.active === true && attendance?.type !== 'PRE_CONSULTATION') ? 'Finalizar e assinar' : 'Finalizar' }}
            </b-button>
          </li>
          <li v-if="['ATTENDANCE', 'SURGERY', 'EXAM', 'ESSILOR_ATTENDANCE'].includes(attendance?.type)">
            <b-button class="dropdow__item" @click="onFinishAttendanceAndPrint" v-b-modal.attendance-review-modal>
              Finalizar e imprimir
            </b-button>
          </li>
          <li v-if="attendance?.type === 'ATTENDANCE'">
            <b-button class="dropdow__item" @click="onFinishLater">
              Finalizar depois
            </b-button>
          </li>
          <li>
            <b-button class="dropdow__item" @click="cancelAttendance">
              Cancelar
            </b-button>
          </li>
        </ul>
      </b-button>
      <Timer />
    </div>

    <BVClinicalReportModal :patientId="attendance?.patient?.id" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Icon from '@/components/General/Icon'
import Avatar from '@/components/General/Avatar'
import ShowHistory from '@/components/General/ShowHistory'
import Timer from '@/components/Attendance/Header/Timer'
import Profile from '@/assets/icons/profile.svg'
// import Sorting from '@/assets/icons/sorting.svg'
// import Exam from '@/assets/icons/exam-icon.svg'
import PreConsultation from '@/assets/icons/pre-consultation.svg'
import Printer from '@/assets/icons/printer.svg'
import InfoPaciente from '@/assets/icons/info-paciente.svg'
import {getClassPriority, getIconPriority, getLabelPriority} from '@/utils/priorityLevelHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { essilorPatientOut } from '@/utils/essilor/essilorIntegrationHelper'

export default {
  components: {
    Icon,
    Avatar,
    ShowHistory,
    Timer,
    Profile,
    // Sorting,
    // Exam,
    PreConsultation,
    Printer,
    InfoPaciente,
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    TooltipInfoPatient: () => import ('../../General/TooltipInfoPatient.vue'),
    Export: () => import ('@/assets/icons/link.svg'),
    BVClinicalReportModal: () => import ('./BVClinicalReportModal.vue'),
    PriorityNormalIcon: () => import('@/assets/icons/priority-normal.svg'),
    PriorityLowIcon: () => import('@/assets/icons/priority-low.svg'),
    PriorityMediumIcon: () => import('@/assets/icons/priority-medium.svg'),
    PriorityHighIcon: () => import('@/assets/icons/priority-high.svg'),
    PriorityVeryHighIcon: () => import('@/assets/icons/priority-very-high.svg'),
    EyeExams: () => import('@/assets/icons/eye-exams.svg'),
  },
  data() {
    return {
      cancelText: {
        PRE_CONSULTATION: 'Cancelar pré-consulta',
        ATTENDANCE: 'Cancelar consulta',
        SURGERY: 'Cancelar consulta cirúrgica',
        ANESTHETIST_EVOLUTION: 'Cancelar evolução',
        TEAM_EVOLUTION: 'Cancelar evolução',
        TEAM_ANNOTATIONS: 'Cancelar anotação',
        EXAM: 'Cancelar exame',
        BV_PRESCRIPTION: 'Cancelar Prescrição BV',
        BV_EXAM: 'Cancelar Benefício Visão',
        ESSILOR_ATTENDANCE: 'Cancelar exames Essilor',
      },
      clinic: getCurrentClinic(),
      chevronState: false,
      openFinishOptions: false,
      surgeryId:  this.$route.params.surgeryId,
      noCids: ['PRE_CONSULTATION', 'ANESTHETIST_EVOLUTION', 'TEAM_EVOLUTION', 'TEAM_ANNOTATIONS', 'EXAM', 'ESSILOR_ATTENDANCE'],
    }
  },
  computed: {
    ...mapGetters(['certificate']),
    ...mapGetters('attendance', ['hasPreConsultation']),
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      loading: state => state.attendance.loading,
      modules: state => state.attendance.form,
      attendance: state => state.attendance.attendance,
    }),
    isBirthday() {
      return this.attendance?.patient?.birthday && moment().format('MM-DD') === moment(this.attendance.patient.birthday).format('MM-DD')
    },
    hasAppointment() {
      return !!this.attendance.appointment
    }
  },
  methods: {
    ...mapActions('attendance', ['finishAttendance', 'closeAttendance']),
    moment,
    openPreConsultation() {
      this.hasPreConsultation && this.$bvModal.show('preconsulting-modal')
    },
    goToProfile() {
      window.open(`/pacientes/${this.attendance.patient.id}/edit`, '_blank')
    },
    handleRedirectToDashboard() {
      if (this.attendance.type === 'ATTENDANCE')
        this.$router.push(`/pacientes/${this.attendance.patient.id}`)
      else if (this.attendance.type === 'SURGERY')
        this.$router.push(`/centro-cirurgico/lista-pacientes`)
      else if (this.attendance.type === 'PRE_CONSULTATION')
        this.$router.push(`/pre-consulta`)
      else if (this.attendance.type === 'EXAM' || this.attendance.type === 'ESSILOR_ATTENDANCE')
        this.$router.push(`/exames`)
      else if (this.attendance.type === 'BV_PRESCRIPTION')
        this.$router.push(`/solicitacoes-pendentes`)
      else if (this.attendance.type === 'BV_EXAM')
        this.$router.push(`/solicitacoes-pendentes`)
    },
    async cancelSurgery() {
      await this.api.updateSurgeryInformation(this.surgeryId, { status: 'Cancelado' })
    },
    handleOpenFinishOptions() {
      this.openFinishOptions = !this.openFinishOptions
    },
    hasCompletedModule () {
      return Object.keys(this.modules).some(key=> {
          return (
            this.modules[key]?.completed
            && this.modules[key]?.scopes.some(scope => {
              return scope === 'PRE_CONSULTATION'
            })
          )
      })
    },
    async onFinishAttendance() {
      this.openFinishOptions = false

      if (
        this.attendance.type === 'PRE_CONSULTATION'
        && !this.hasCompletedModule()
      ) {
        this.$toast.warning('Preencha algum campo para finalizar a pré-consulta')
        return
      }
      await essilorPatientOut(this.attendance, this.clinic.id)

      const finished = await this.finishAttendance({ noCid: this.noCids.includes(this.attendance.type) })
      if (this.surgeryId && finished)
        this.$router.push(`/centro-cirurgico/dashboard/${this.surgeryId}`)

      this.$emit('onFinishAttendance')
    },
    onFinishAttendanceAndPrint() {
      this.openFinishOptions = false
      this.$bvModal.show('attendance-review-modal')
    },
    onFinishLater() {
      this.openFinishOptions = false
      this.$bvModal.show('pause-modal')
    },
    resetDropdownState() {
      setTimeout(() => {
        this.openFinishOptions = false
      }, 1300);
    },
    async cancelAttendance() {
      this.openFinishOptions = false

      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente cancelar a consulta atual? Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          const isLoading = this.$loading.show()
          await essilorPatientOut(this.attendance, this.clinic.id)
          const data = { action: 'end_attendance', canceled: true, type: this.attendance?.type, attendanceId: this.attendance.id }
          try {
            const response = await this.api.updateAttendance(this.attendance.clinic.id, this.attendance.patient.id, data)
            this.closeAttendance()
            if (response.data.success) {
              this.canceled = true
              if (this.surgeryId)
                this.cancelSurgery()
              this.handleRedirectToDashboard()
            }
          } catch (error) {
            this.$toast.error(error.message)
          } finally {
            isLoading.hide()
          }
        }
       })
    },
    getClassPriority(priorityLevel) {
      return getClassPriority(priorityLevel)
    },
    getIconPriority(priorityLevel) {
      return getIconPriority(priorityLevel)
    },
    getLabelPriority(priorityLevel) {
      return getLabelPriority(priorityLevel)
    },
    async importEssilorExams() {
      const isLoading = this.$loading.show()
      this.api.importEssilorExams(
        {
          patient_id: this.attendance.patient.id,
          attendance_id: this.attendance.id,
          clinic_id: this.clinic.id
        }
    ).then(res => {
        this.$toast.success(res.data.message)
        this.$router.go()
      }).catch(err => {
        this.$toast.error(err.message)
      }).finally(() => {
          isLoading.hide()
        }
      )
    }
  },
}
</script>

<style lang="scss" scoped>
  .patient-and-doctor-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px 8px 0 0;
    background: white;
    border-bottom: 1px solid var(--neutral-200);

    .left-options {
      display: flex;
      flex-direction: row;
      align-items: center;

      .padding-border {
        padding: 8px 14px;
        border-right: 1px solid var(--neutral-200);
      }
      .icon-wrapper {
        border-radius: 8px;
        padding: 10px;
        background: var(--blue-100);
        cursor: pointer;

        .icon {
          width: 24px;
          height: 24px;
          stroke: var(--blue-700);
        }
        &.active {
          background: var(--states-success-soft-green);
        }

        &.inactive {
          .icon {
            stroke: var(--blue-300);
          }
        }
      }
  }
    .center-options {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin: 5px 0px;
      border-right: 1px solid var(--neutral-200);
      border-left: 1px solid var(--neutral-200);

      .padding-border {
        padding: 10px 14px;
      }
      .icon-wrapper {
        border-radius: 8px;
        padding: 10px;
        background: var(--blue-100);
        cursor: pointer;

        .icon {
          width: 24px;
          height: 24px;
          &.stroke{
            stroke: var(--blue-700);
          }
          &.essilor-exams{
            fill: var(--blue-700);
          }
        }
        &.active {
          background: var(--states-success-soft-green);
        }

        &.inactive {
          .icon {
            stroke: var(--blue-300);
          }
        }

        &.bv {
          background: #FFEBCC;
          .icon {
            stroke: var(--orange);
          }
        }
      }
  }
    .patient-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 0px 16px;

      .patient-border {
        margin-right: 8px;
      }

      .patient-row {
        display: flex;
        flex-direction: row;
        flex: 1;
      }
      .patient-name {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 10px;
        font-weight: 700;
        font-size: 18px;
        color: var(--dark-blue);

        .confetti {
          width: 36px;
        }

        .patient-age {
          font-weight: 600;
          font-size: 16px;
          color: #525c7a;
        }
      }

      .medical-record {
        font-weight: 400;
        font-size: 12px;
        color: var(--type-placeholder);
        padding-top: 3px;
        span {
          font-weight: 600;
          color: var(--type-active);
        }
      }

      .icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    &.normal {
      color: #4a4a4a;
    }
    &.low {
      color: #305bf2;
    }
    &.medium {
      color: #00c773;
    }
    &.high {
      color: #ffcc18;
    }
    &.very-high {
      color: #f63220;
    }
  }
    }
    .right-options {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-right: 16px;
      gap: 10px;

      .finish-dropdown__button {
        border: none;
        min-width: 190px;
        position: relative;
        background: lighten(#305bf2, 40%);
        padding: 6px 12px;
        border-radius: 30px;

        .dropdown-call__button {
          font-weight: 700;
          color: var(--blue-500);
          background: lighten(#305bf2, 40%);

          display: flex;
          align-items: center;
          justify-content: space-between;

          .chevron__container {
            background: lighten(#305bf2, 30%);
            border-radius: 8px;
            padding: 1px;
          }

          .chevron {
            width: 24px;
            height: 24px;
            transition: all 0.5s;
            stroke: var(--blue-500);
          }

          .rotate {
            transform: rotate(-180deg);
          }
        }

        .dropdown__container {
          position: absolute;
          top: 40px;
          left: 0;

          margin: 0;
          z-index: 2;
          width: 100%;
          padding: 8px 0;
          border-radius: 8px;
          background: var(--default-white);
          border: 1px solid var(--neutral-300);
          box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);

          .dropdow__item {
            width: 100%;
            border: none;
            border-radius: 0;
            text-align: start;
            padding: 0.5rem 1.4rem;
            color: var(--type-active);
            background: var(--default-white);

            &:hover {
              background: var(--neutral-100);
            }
          }
        }
      }

      .doctor-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

      .cancel-btn {
        border-radius: 100px;
        background-color: transparent;
        border-color: var(--states-error);
        color: var(--states-error);
        white-space: nowrap;
        font-size: min(1.2vw, 14px);
      }
    }
    .divisor {
    margin: 0 5px;
    color: #d9dff2;
    @media screen and (max-width: 1280px) {
      color: white;
    }
  }
  }
</style>
